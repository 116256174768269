<template>
  <div class="layout">
    <!-- top navbar -->
    <MDBNavbar id="mainNavbar" container class="pe-3 sticky-top" style="padding-left: 11.6px;">
      <MDBBtn v-if="!hideSidenav" color="tertiary" aria-controls="#mainSidenav" @click="mainSidenav = !mainSidenav"
        id="mainSidenavTogglerNavbar" style="padding-left: 0;">
        <MDBIcon icon="bars" />
      </MDBBtn>
      <MDBNavbarNav right>
        <MDBNavbarItem>
          <MDBDropdown btnGroup align="lg-end" v-model="userDropdown" id="userDropdown"
            class="gap-md-4 gap-3 d-flex flex-column flex-md-row">
            <p class="m-auto text-dark current-date" style="font-size: 14px;">
              <span class="fw-bold me-2">{{ currentTime }}</span>{{ currentDate }}
            </p>
            <template v-if="isUser">
              <MDBTooltip v-model="assetLabelTooltip" class="d-flex align-items-center justify-content-end"
                placement="bottom">
                <template #reference>
                  <MDBBtn color="primary" class="rounded-pill" size="sm" style="font-size: 11.5px;">
                    <MDBIcon icon="lock" class="fas fa-fw me-1" />
                    ID: {{ userDetail.assetLabelName }}
                  </MDBBtn>
                </template>
                <template #tip>
                  Asset Label
                </template>
              </MDBTooltip>
            </template>
            <MDBDropdownToggle tag="a" class="nav-link d-flex align-items-center justify-content-end"
              @click="userDropdown = !userDropdown" color="dark">
              <img :src="imageUrl" class="img-fluid rounded-circle logo2" alt="user-image" />
              <div class="d-flex flex-column justify-content-center align-items-center mx-2">
                <div class="text-capitalize user-font" style="font-size: 14.5px;">
                  {{ userDetail.firstName }}
                  {{ userDetail.lastName }}
                </div>
                <span style="color: var(--blue);text-transform: uppercase;font-size: 11px;font-weight: bold;">{{
        setUserType(userDetail.userTypeId) }}</span>
              </div>
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem class="nav-link-item" href="#/UserSettings">
                User Settings
              </MDBDropdownItem>
              <MDBDropdownItem class="nav-link-item" href="#" @click="handleLogout">
                Logout
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
      </MDBNavbarNav>
    </MDBNavbar>
    <!-- side navbar -->
    <div v-if="!hideSidenav">
      <MDBSideNav v-model="mainSidenav" id="mainSidenav" :mode="sideNavMode" contentSelector="#content">
        <div class="title">
          <MDBBtn color="tertiary" aria-controls="#mainSidenav" @click="mainSidenav = !mainSidenav"
            id="mainSidenavTogglerSidenav">
            <MDBIcon icon="bars" />
          </MDBBtn>
          <div class="w-100 d-flex justify-content-center pe-4">
            <img @click="toDashboard" :src="logoUrl" alt="creator-shield-logo"
              class="rounded-circle img-fluid object-fit-contain logo" />
          </div>
        </div>
        <hr class="m-0" />
        <MDBSideNavMenu scrollContainer>
          <MDBSideNavItem>
            <MDBSideNavLink to="/Dashboard">
              <MDBIcon icon="home" class="fas fa-fw me-3" size="lg" />
              Dashboard
              <hr class="mb-0" />
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink to="/ClaimsList">
              <MDBIcon icon="clipboard-list" class="fas fa-fw me-3" size="lg" />
              {{ isUser ? 'My Claims' : 'Claims List' }}
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem v-if="isUser">
            <MDBSideNavLink to="/NewClaim">
              <MDBIcon icon="file-alt" class="fas fa-fw me-3" size="lg" />
              New Claim
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem v-if="isUser">
            <MDBSideNavLink to="/BulkClaims">
              <MDBIcon icon="folder-plus" class="fas fa-fw me-3" size="lg" />
              Bulk Claims
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem v-if="isUser">
            <MDBSideNavLink
              href="https://www.dropbox.com/scl/fo/j2543l4jion4xrcq9llh9/h?rlkey=25oqfir3tefqca7138qibwyzv&e=1&dl=0"
              target="_blank" title="Video Library">
              <MDBIcon icon="file-video" class="fas fa-fw me-3" size="lg" />
              Video Library
            </MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavMenu>
      </MDBSideNav>
    </div>
    <!-- impersonating -->
    <MDBContainer id="content" fluid>
      <MDBAlert v-if="hideSidenav" class="impersonate-banner" static>
        <i class="fas fa-user-secret me-3"></i>
        <p>
          VIEWING as
          <strong>{{ lastName }}, {{ firstName }}</strong> [IMPERSONATING]
        </p>
        <MDBTooltip v-model="exitUserManagement">
          <template #reference>
            <i class="fas fa-backspace" @click="exitImpersonation"></i>
          </template>
          <template #tip> Exit Impersonation </template>
        </MDBTooltip>
      </MDBAlert>
      <slot></slot>
    </MDBContainer>
  </div>
</template>

<script setup>
import {
  MDBNavbar, MDBNavbarNav, MDBNavbarItem, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBContainer, MDBAlert, MDBTooltip, MDBBtn, MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBIcon
} from "mdb-vue-ui-kit";
import { computed, onMounted, ref, watch, watchEffect, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { useUserDetailStore } from "@/store/userDetailStore";
import { GetCurrent } from "@/services/Users/GetCurrent";
import { Logout } from "@/services/Logout/Logout";
import { storeToRefs } from "pinia";
import { useImpersonateStore } from "@/store/impersonateStore";
import { useTimeout } from "@vueuse/core";
import { showCurrentDateTimeWithSeconds } from "../../helpers/parseDate";
import Intercom from '@intercom/messenger-js-sdk';

const isMobile = ref(window.innerWidth <= 767);
const sideNavMode = ref(isMobile.value ? 'over' : 'side');
const mainSidenav = ref(isMobile.value ? false : true);

const updateResolution = () => {
  isMobile.value = window.innerWidth <= 767;
};

watchEffect(() => {
  sideNavMode.value = isMobile.value ? 'over' : 'side';
  mainSidenav.value = isMobile.value ? false : true;
});

onMounted(() => {
  window.addEventListener('resize', updateResolution);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateResolution);
});

const logo = require("@/assets/VVV/images/Layout/veryviralvisuals.png");
const logoUrl = computed(() => logo);

const router = useRouter();
const toDashboard = () => {
  router.push("/Dashboard");
};

const adjustmentsURL = ref("");

const defaultImage = require("@/assets/user-default.png");
const imageUrl = computed(() => defaultImage);

const userDropdown = ref(false);
const userDetailStore = useUserDetailStore();
const { userDetail, isAdmin, isUser } = storeToRefs(userDetailStore);

onMounted(async () => {
  const response = await GetCurrent();
  userDetail.value = response;
  setLiveChat(response);
  adjustmentsURL.value = `/Users/${response.id}/Adjustments`;
  setCurrentDate()
  setInterval(setCurrentDate, 1000);
});

const currentTime = ref("");
const currentDate = ref("");

const setCurrentDate = () => {
  const { time, date } = showCurrentDateTimeWithSeconds();
  currentTime.value = time;
  currentDate.value = date;
};

const handleLogout = () => {
  Logout().then(() => {
    router.push("/Login");
  });
};

const exitUserManagement = ref(false);

const { hideSidenav, exitImpersonation, firstName, lastName } =
  useImpersonate();
function useImpersonate() {
  const impersonateStore = useImpersonateStore();
  const { isImpersonating, userImpersonateDetail } =
    storeToRefs(impersonateStore);
  const { firstName, lastName } = userImpersonateDetail.value;
  const hideSidenav = ref(false);
  const { start, ready } = useTimeout(3000, { controls: true });

  onMounted(async () => {
    if (isImpersonating.value) {
      mainSidenav.value = false;
      isAdmin.value = userImpersonateDetail.value.userTypeId === 1;
      isUser.value = userImpersonateDetail.value.userTypeId != 1;
      adjustmentsURL.value = `/Users/${userImpersonateDetail.value.id}/Adjustments`;
      start();
    }
  });

  watch(ready, (newVal) => {
    hideSidenav.value = newVal && isImpersonating.value;
  });

  const exitImpersonation = () => {
    isImpersonating.value = false;
    router.push({ name: "UserManagement" });
  };

  return {
    hideSidenav,
    exitImpersonation,
    firstName,
    lastName,
  };
}

const setUserType = (userTypeId) => {
  switch (userTypeId) {
    case 1:
      return "Admin";
    case 2:
      return "User";
    case 3:
      return "Distributor";
    case 4:
      return "Super Admin";
    default:
      return "-";
  }
}

const assetLabelTooltip = ref();

const setLiveChat = (user) => {
  Intercom({
    app_id: 'ts0mhyws',
    user_id: user.id,
    name: user.name,
    email: user.email,
    created_at: Math.floor(Date.now() / 1000),
  });
}
</script>

<style>
:root {
  --white: #ffffff;
  --primary: #5C40EA;
  --secondary: #e2e6ef;
  --accent: #02061b;
  --blue: #1f87fc;
  --mdb-body-font-size: 14px;
}

.sidenav-link:hover {
  background-color: var(--primary) !important;
  color: white !important;
}

.tooltip-inner {
  font-family: "Poppins", sans-serif !important;
  background-color: var(--primary);
  font-size: 12px;
  padding: 3px 12px;
}

.logo {
  width: 180px;
}

@media screen and (max-width: 440px) {
  .logo {
    width: 150px;
  }

  .user-font,
  .current-date {
    font-size: 14px;
  }
}

@media screen and (max-width: 395px) {
  .logo {
    width: 140px;
  }

  .user-font,
  .current-date {
    font-size: 13px;
  }
}

@media screen and (max-width: 373px) {
  .logo {
    width: 120px;
  }

  .user-font,
  .current-date {
    font-size: 12px;
  }
}
</style>

<style scoped>
.btn-primary {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  gap: 5px;
}

.layout {
  background-color: var(--secondary);
  font-family: "Poppins", sans-serif;
  overflow-y: auto;
}

#mainNavbar {
  background-color: white;
  color: var(--primary);
}

#mainSidenavTogglerNavbar,
#mainSidenavTogglerSidenav {
  font-size: 16px;
  color: var(--primary);
  box-shadow: none;
}

:global(#mainSidenav.sidenav) {
  background-color: white;

  .title {
    height: 58px;
    display: flex;
    justify-content: space-evenly;
  }

  .logo {
    object-fit: contain;
  }
}

:global(#mainSidenav + .sidenav-backdrop) {
  background-color: rgba(0, 0, 0, 0.4);
}

:global(#mainSidenav .sidenav-menu) {
  color: var(--accent);
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.logo2 {
  width: 35px;
}

.nav-link {
  color: var(--accent);
  padding: 0;
}

:deep(#mainSidenav .nav-link-item:hover) {
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}

.sidenav-link {
  border-radius: 0;
}

:deep(#mainSidenav .nav-collapsible a.active) {
  background-color: white;
  color: var(--accent);
}

.sidenav-link.active,
.nav-collapsible a:hover {
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}

#userDropdown {
  box-shadow: none;
}

#content {
  font-family: "Poppins", sans-serif;
  background-color: var(--secondary);
  margin-inline: auto !important;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 19px;
}

.impersonate-banner {
  display: flex;
  align-items: center;
  color: var(--primary);
  background-color: var(--accent);
}

.impersonate-banner p {
  margin-bottom: 0;
  margin-right: auto;
}

.impersonate-banner :deep(span) {
  cursor: pointer;
}

.badge {
  background-color: var(--primary) !important;
}

.sidenav-link.active .badge,
.sidenav-link:hover .badge {
  color: var(--primary);
  background-color: white !important;
}
</style>
